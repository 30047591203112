import { useReducer } from 'react';
import invariant from 'tiny-invariant';
import { z } from 'zod';

// Chunk size in bytes. Minimum 5 MiB for S3 multipart uploads
const CHUNK_SIZE = 10_000_000;

export type FilePart = {
  partNumber: number;
  startBytes: number;
  endBytes: number;
  eTag: string | null;
  status: 'pending' | 'uploading' | 'success' | 'failed';
};

export type QueueItem = {
  tmpId: number;
  file: File;
  status: 'pending' | 'uploading' | 'success' | 'failed' | 'aborting';
  error: string | null;
  parts: FilePart[];
  token: string | null;
  uploadPath: string | null;
};

export type Queue = {
  status: 'idle' | 'uploading';
  items: QueueItem[];
  nextId: number;
};

function chunkFile(file: File, chunkSize: number): FilePart[] {
  const totalParts = Math.floor(file.size / chunkSize) + 1;

  return new Array(totalParts).fill(undefined).map<FilePart>((_, i) => {
    const partNumber = i + 1; // S3 parts start at 1
    const startBytes = i * chunkSize;
    const endBytes = partNumber * chunkSize;

    return {
      partNumber,
      startBytes,
      endBytes,
      eTag: null,
      status: 'pending',
    };
  });
}

type Action =
  | { type: 'ADD_FILES'; files: File[] }
  | { type: 'SET_QUEUE_STATUS'; status: Queue['status'] }
  | { type: 'ITEM_START'; tmpId: number }
  | {
      type: 'ITEM_INITIALIZED';
      tmpId: number;
      token: string;
      uploadPath: string;
    }
  | { type: 'ITEM_SUCCESS'; tmpId: number }
  | { type: 'ITEM_FAILED'; tmpId: number; error: string | null }
  | { type: 'ITEM_ABORT'; tmpId: number }
  | { type: 'ITEM_REMOVE'; tmpId: number }
  | { type: 'PART_START'; tmpId: number; partNumber: number }
  | { type: 'PART_SUCCESS'; tmpId: number; partNumber: number; eTag: string }
  | { type: 'PART_FAILED'; tmpId: number; partNumber: number }
  | { type: 'PART_RESET'; tmpId: number; partNumber: number };

function reducer(state: Queue, action: Action): Queue {
  console.log('useUploadQueue reducer', state, action);

  const updateItem = (
    items: typeof state.items,
    tmpId: number,
    nextItem: Partial<QueueItem>,
  ): QueueItem[] =>
    items.map(item => {
      if (item.tmpId === tmpId) return { ...item, ...nextItem };
      return item;
    });

  const updatePart = (
    items: typeof state.items,
    tmpId: number,
    partNumber: number,
    nextPart: Partial<FilePart>,
  ) => {
    return items.map(item => {
      if (item.tmpId !== tmpId) return item;
      const nextParts = item.parts.map(part => {
        if (part.partNumber !== partNumber) return part;
        return { ...part, ...nextPart };
      });
      return { ...item, parts: nextParts };
    });
  };

  switch (action.type) {
    case 'ADD_FILES': {
      const newItems: QueueItem[] = action.files.map((file, i) => ({
        tmpId: state.nextId + i,
        file,
        status: 'pending',
        error: null,
        parts: chunkFile(file, CHUNK_SIZE),
        token: null,
        uploadPath: null,
      }));

      return {
        ...state,
        items: state.items.concat(newItems),
        nextId: state.nextId + action.files.length,
      };
    }

    case 'SET_QUEUE_STATUS':
      return { ...state, status: action.status };

    case 'ITEM_START':
      return {
        ...state,
        items: updateItem(state.items, action.tmpId, {
          status: 'uploading',
        }),
      };

    case 'ITEM_INITIALIZED':
      return {
        ...state,
        items: updateItem(state.items, action.tmpId, {
          token: action.token,
          uploadPath: action.uploadPath,
        }),
      };

    case 'ITEM_SUCCESS':
      return {
        ...state,
        items: updateItem(state.items, action.tmpId, {
          status: 'success',
          error: null,
        }),
      };

    case 'ITEM_FAILED':
      return {
        ...state,
        items: updateItem(state.items, action.tmpId, {
          status: 'failed',
          error: action.error,
        }),
      };

    case 'ITEM_ABORT':
      return {
        ...state,
        items: updateItem(state.items, action.tmpId, { status: 'aborting' }),
      };

    case 'ITEM_REMOVE':
      return {
        ...state,
        items: state.items.filter(item => item.tmpId !== action.tmpId),
      };

    case 'PART_START':
      return {
        ...state,
        items: updatePart(state.items, action.tmpId, action.partNumber, {
          status: 'uploading',
        }),
      };

    case 'PART_SUCCESS':
      return {
        ...state,
        items: updatePart(state.items, action.tmpId, action.partNumber, {
          status: 'success',
          eTag: action.eTag,
        }),
      };

    case 'PART_FAILED':
      return {
        ...state,
        items: updatePart(state.items, action.tmpId, action.partNumber, {
          status: 'failed',
        }),
      };

    case 'PART_RESET':
      return {
        ...state,
        items: updatePart(state.items, action.tmpId, action.partNumber, {
          status: 'pending',
        }),
      };
  }
}

const uploadPartResSchema = z
  .object({
    part_number: z.number().int().positive(),
    e_tag: z.string().min(1),
  })
  .transform(d => ({
    partNumber: d.part_number,
    eTag: d.e_tag,
  }));

async function uploadPart(
  uploadPath: string,
  token: string,
  partNumber: number,
  chunk: Blob,
) {
  const formData = new FormData();
  formData.append('token', token);
  formData.append('part_number', partNumber.toString());
  formData.append('file_data', chunk);

  try {
    const res = await fetch(uploadPath, {
      method: 'POST',
      body: formData,
    });
    const json = await res.json();

    return uploadPartResSchema.parse(json);
  } catch (err) {
    console.log(err);
    throw new Error('Error uploading chunk');
  }
}

export type CompletedFilePart = { partNumber: number; eTag: string };

export type UseUploadQueueConfig = {
  initializeUploadFn: (
    file: File,
  ) => Promise<{ token: string; uploadPath: string }>;
  completeUploadFn: (
    token: string,
    parts: CompletedFilePart[],
  ) => Promise<void>;
  abortUploadFn: (token: string) => Promise<void>;
  onItemCompleted?: () => void;
};

export function useUploadQueue(config: UseUploadQueueConfig) {
  const [state, dispatch] = useReducer(reducer, {
    status: 'idle',
    items: [],
    nextId: 0,
  });

  async function addFiles(files: File[]) {
    dispatch({ type: 'ADD_FILES', files: files });
  }

  async function startUpload() {
    dispatch({ type: 'SET_QUEUE_STATUS', status: 'uploading' });

    const itemsToUpload = state.items.filter(
      item => item.status === 'pending' || item.status === 'failed',
    );

    for (const item of itemsToUpload) {
      dispatch({ type: 'ITEM_START', tmpId: item.tmpId });

      // Initialize the multipart upload by getting a token and upload path.
      // If one exists from a failed attempt, we'll use the existing ones.
      let { token, uploadPath } = item;
      if (!token && !uploadPath) {
        try {
          const res = await config.initializeUploadFn(item.file);
          token = res.token;
          uploadPath = res.uploadPath;
          dispatch({
            type: 'ITEM_INITIALIZED',
            tmpId: item.tmpId,
            token,
            uploadPath,
          });
        } catch (err) {
          console.log('Error initializing upload', err);
          dispatch({
            type: 'ITEM_FAILED',
            tmpId: item.tmpId,
            error: 'Failed to initialize multipart upload.',
          });
          continue;
        }
      }
      // TS can't seem to figure out these are not-null at this point
      invariant(token && uploadPath, 'token or uploadPath is not set!');

      // These are the parts that have been successfully loaded.
      // Start by building a list of potentially previously-successful uploads.
      // When a part uploads successfully, it'll be pushed onto this list to keep track.
      const completedParts = item.parts.reduce<CompletedFilePart[]>(
        (acc, cur) => {
          if (cur.status === 'success' && cur.eTag) {
            acc.push({ partNumber: cur.partNumber, eTag: cur.eTag });
          }
          return acc;
        },
        [],
      );

      const partsToUpload = item.parts.filter(
        part => part.status === 'pending' || part.status === 'failed',
      );

      item.parts.forEach(part => {
        if (part.status === 'failed') {
          dispatch({
            type: 'PART_RESET',
            tmpId: item.tmpId,
            partNumber: part.partNumber,
          });
        }
      });

      let fileChunk: Blob;
      for (const part of partsToUpload) {
        dispatch({
          type: 'PART_START',
          tmpId: item.tmpId,
          partNumber: part.partNumber,
        });

        fileChunk = item.file.slice(part.startBytes, part.endBytes);

        try {
          const { partNumber, eTag } = await uploadPart(
            uploadPath,
            token,
            part.partNumber,
            fileChunk,
          );
          completedParts.push({ partNumber, eTag });
          dispatch({
            type: 'PART_SUCCESS',
            tmpId: item.tmpId,
            partNumber,
            eTag,
          });
        } catch (err) {
          console.log('Error uploading part:', err);
          dispatch({
            type: 'PART_FAILED',
            tmpId: item.tmpId,
            partNumber: part.partNumber,
          });
        }
      }

      if (completedParts.length === item.parts.length) {
        try {
          await config.completeUploadFn(token, completedParts);
          dispatch({ type: 'ITEM_SUCCESS', tmpId: item.tmpId });
          if (config?.onItemCompleted) {
            config.onItemCompleted();
          }
        } catch (err) {
          console.log('Error completing multipart upload', err);
          dispatch({
            type: 'ITEM_FAILED',
            tmpId: item.tmpId,
            error: 'There was a problem finalizing the upload.',
          });
        }
      } else {
        const numFailed = item.parts.length - completedParts.length;
        dispatch({
          type: 'ITEM_FAILED',
          tmpId: item.tmpId,
          error: `${numFailed} parts failed to upload.`,
        });
      }
    }

    dispatch({ type: 'SET_QUEUE_STATUS', status: 'idle' });
  }

  async function abortItem(tmpId: number) {
    dispatch({ type: 'ITEM_ABORT', tmpId });

    const item = state.items.find(si => si.tmpId === tmpId);

    if (item?.token) {
      console.log('Trying to abort item:', item);
      await config.abortUploadFn(item.token);
      console.log('Item aborted.');
    }

    dispatch({ type: 'ITEM_REMOVE', tmpId });
  }

  return {
    queue: state,
    startUpload,
    addFiles,
    abortItem,
  };
}
